import { Info } from '@mui/icons-material';

const ProgressChip = ({ message }: any) => {
  const { body } = message;
  return (
    <div className="bg-[#F2F4F7] items-center text-[12px] rounded-xl px-4 py-2 flex self-center justify-between">
      <Info className="text-[18px]" />
      <p className="mx-2">{body.replace('[', '').replace(']', '')}</p>
    </div>
  );
};

export default ProgressChip;
