import { useCallback } from 'react';
import { useAppSelector } from 'hooks';
import { db } from 'utils/firebase/firebaseInit';
import levenshtein from 'utils/format/levenshtein';
import { IFilesAttached, IMessageReference } from 'types';
import { addDoc, collection, Timestamp } from 'firebase/firestore';

export const useChatCreateMessage = () => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const messageQuoted = useAppSelector((state) => state.messageQuoted);
  const sendAs = useAppSelector((x) => x.sendAs.selected);
  const { messages } = useAppSelector((state) => state.automationAi);

  function removeUndefinedKeys(obj: any) {
    const cleaned: any = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined) {
        cleaned[key] = obj[key];
      }
    });
    return cleaned;
  }

  return useCallback(
    async (
      message: string,
      filesList: IFilesAttached[],
      references: IMessageReference[] | null,
    ) => {
      if (coachData) {
        // we need to inject the badge share message
        const body = message ? { body: message } : {};
        const userAvatarUrlFrom = coachData?.avatarUrl
          ? { userAvatarUrlFrom: coachData?.avatarUrl }
          : {};

        const virtualUser =
          sendAs?.id !== coachData?.id
            ? {
                virtualUserIdFrom: sendAs?.id ?? '',
                virtualUserNameFrom: sendAs?.firstName ?? '',
                virtualUserAvatarUrlFrom: sendAs?.avatarUrl ?? '',
              }
            : {};

        const replyToMessage = messageQuoted.id
          ? { replyToMessage: removeUndefinedKeys(messageQuoted) }
          : {};
        const referencesMessage = references ? { references: references } : {};
        const editionScore =
          body && messages.length > 0
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              levenshtein(body.body, messages[messages.length - 1].content)
            : null;

        const details = {
          userId: customer?.id,
          userIdFrom: coachData.id,
          userNameFrom: coachData?.displayName ?? coachData?.firstName + ' ' + coachData?.lastName,
          ...userAvatarUrlFrom,
          ...body,
          files: filesList,
          ...replyToMessage,
          createdAt: Timestamp.now(),
          isViewed: false,
          isDeleted: false,
          ...virtualUser,
          ...referencesMessage,
          aiGeneratedMessage: messages.length > 0 ? messages[messages.length - 1].content : null,
          editionScore,
        };
        await addDoc(collection(db, 'messages'), details);
      }
    },
    [coachData, customer, sendAs, messageQuoted, messages],
  );
};
