import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetFeedback } from 'store/customers/progressFeedbackSlice';
import { resetWeeks } from 'store/program/weekProgramSlice';
import { resetMessages } from 'store/automation/aiSlice';
import { clearQuotedMessage } from 'store/chat/quotedMessageSlice';
import { disableFeedbackTool } from 'store/chat/sendMessageSlice';
import { AppBarTitle, StyledAppBar } from './styles';
import ActionFeedbackForm from '../DrawerActionFeedbackForm';
import MessageFeedbackForm from '../DrawerMessageFeedbackForm';
import { IframeCloseIcon, StyledDrawer } from 'styles/drawers';
import { useDrawerOffset } from 'hooks/chat/useDrawerOffset';

const DrawerFeedbackProgress = () => {
  const dispatch = useAppDispatch();
  const { openedDrawer, simpleFeedback } = useAppSelector((state) => state.progressFeedback);
  const getDrawerOffset = useDrawerOffset();

  const handleCloseDrawer = () => {
    dispatch(disableFeedbackTool());
    dispatch(resetWeeks());
    dispatch(resetMessages());
    dispatch(resetFeedback());
    dispatch(clearQuotedMessage());
  };

  return (
    <StyledDrawer
      isOpen={!!openedDrawer}
      variant="persistent"
      anchor="right"
      open={openedDrawer}
      width={400}
      rightOffset={getDrawerOffset('feedback')}
    >
      <StyledAppBar position="sticky">
        <Toolbar>
          <AppBarTitle>Give feedback or answer question</AppBarTitle>
          <IframeCloseIcon as={CloseIcon} onClick={handleCloseDrawer} />
        </Toolbar>
      </StyledAppBar>
      {!simpleFeedback && <ActionFeedbackForm />}
      {simpleFeedback && <MessageFeedbackForm />}
    </StyledDrawer>
  );
};

export default DrawerFeedbackProgress;
