import { DocumentReference, doc, getDoc } from '@firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { ProgramAction } from '@fitmate-coach/fitmate-types';

export const getProgramActionRef = (id: string, actionId: string) =>
  doc(db, 'users', id, 'programActions', actionId) as DocumentReference<ProgramAction>;

export async function getById(
  actionId: string,
  userId: string,
): Promise<ProgramAction | undefined> {
  const docRef = getProgramActionRef(userId, actionId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return undefined;
  }
  return docSnap.data();
}
