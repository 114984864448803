import { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useUserProfile } from 'hooks/profile/useUserProfile';
import { convertCmToInches, convertKgToLb } from 'utils/format/measures';
import {
  dateConvertFromDatePicker,
  dateConvertToDatePicker,
  dateOfBirthDefault,
} from 'utils/format/datetime';
import { setUserStatus } from './helper';
import { BottomBlock, ButtonSubmit, Container, MainBlock, StyledButton } from './styles';
import { IFormUserData, toTimestamp } from 'types';
import { ClientUser } from '@fitmate-coach/fitmate-types';
import PartnerDataInfo from './PartnerDataInfo';
import KeyInfo from './KeyInfo';
import OtherInfo from './OtherInfo/OtherInfo';
import PaymentInfo from './PaymentInfo';
import EmailInfo from './EmailInfo';
import OnboardingInfo from './OnBoardingInfo';
import FeatureFlagsInfo from './FeatureFlagsInfo';
import { fetchCustomerById } from 'store/customers/customerInfosSlice';
import { useAppDispatch } from 'hooks';
import { format } from 'date-fns';
import PartnerReferralDataInfo from './PartnerReferralDataInfo';
import VideoIntroCall from './VideoIntroCall';
import { toast } from 'sonner';
import ReferralInfo from './Referral';
import FitmateInfo from './FitmateInfo';

const ChatUserProfileEdit = ({
  userId,
  userData,
  toggleEditingMode,
}: {
  userId: string;
  toggleEditingMode: () => void;
  userData: ClientUser | null;
}) => {
  const dispatch = useAppDispatch();
  const { editUserProfile } = useUserProfile(userId);
  const [isLbs, setIsLbs] = useState<boolean>(true);
  const [isInches, setIsInches] = useState<boolean>(true);
  const [isGlp1drugs, setIsGlp1drugs] = useState<boolean>(userData?.glp1drugs || false);
  const [isCallCompleted, setIsCallCompleted] = useState<boolean>(
    userData?.introCallCompleted || false,
  );
  const [isCoachSentRequest, setIsCoachSentRequest] = useState<boolean>(
    userData?.referralRequest?.coachSentRequest || false,
  );
  const [isTestAccount, setIsTestAccount] = useState<boolean>(userData?.isTestAccount || false);

  const userForm = useForm<IFormUserData>({
    defaultValues: {
      partnerReferralUid: userData?.partnerReferralUid || '',
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      gender: userData?.gender,
      weight: userData?.weight,
      height: userData?.height,
      dateOfBirth:
        typeof userData?.dateOfBirth === 'number' && userData?.dateOfBirth !== 0
          ? dateConvertToDatePicker(userData?.dateOfBirth)
          : dateOfBirthDefault(),
      pregnancy: userData?.pregnancy,
      stressLevel: userData?.lifestyle?.stressLevel,
      physicalActivity: userData?.lifestyle?.physicalActivity,
      proteinsBreakfast: userData?.dietaryHabits?.proteinsBreakfast,
      proteinsLunches: userData?.dietaryHabits?.proteinsLunches,
      simpleCarbs: userData?.dietaryHabits?.simpleCarbs,
      foodCravings: userData?.dietaryHabits?.foodCravings,
      familyStatus: userData?.familyStatus,
      location: userData?.location,
      dietaryPreferences: userData?.dietaryPreferences,
      occupation: userData?.occupation,
      otherMemorableInfo: userData?.otherMemorableInfo,
      healthGoals: userData?.healthGoals || undefined,
      paymentStatus: setUserStatus(userData),
      paymentEmail: userData?.billingData?.email,
      paymentCreate: toTimestamp(userData?.createdAt, 0).seconds,
      paymentReferralUrl: userData?.billingData?.referralLink?.url,
      paymentReferralEnabled: !!userData?.billingData?.isReferralLinkEnabled,
      paymentIsPaid: !!userData?.billingData?.isPaid,
      paymentReferralCurrency: userData?.billingData?.referralLink?.currency,
      paymentReferralWasShow: !!userData?.billingData?.referralLink?.wasShown,
      email: userData?.email,
      glp1drugs: userData?.glp1drugs || false,
      diabetes: userData?.diabetes || 0,
      medicalClearance: userData?.partnerData?.medicalClearance ?? '',
      cancellationDate: userData?.billingData?.cancellationDate
        ? format(userData?.billingData?.cancellationDate.toDate(), 'yyyy-MM-dd')
        : undefined,
      introCallCompleted: userData?.introCallCompleted || false,
      cancellationPauseRequest: userData?.cancellationPauseRequest,
      cancellationPauseOutcome: userData?.cancellationPauseOutcome,
      coachSentRequest: userData?.referralRequest?.coachSentRequest,
      isTestAccount: userData?.isTestAccount ?? false,
    },
  });

  const [featureFlags, setFeatureFlags] = useState<string[] | unknown[]>(
    userData?.featureFlags || [],
  );

  const {
    formState: { dirtyFields },
  } = userForm;

  const onSubmit: SubmitHandler<IFormUserData> = async (user: IFormUserData) => {
    const newWasShow = dirtyFields.paymentReferralUrl ? false : user.paymentReferralWasShow;
    const dateOfBirthValue = user.dateOfBirth ? dateConvertFromDatePicker(user.dateOfBirth) : 0;
    const cancellationDate = user.cancellationDate ? new Date(user.cancellationDate) : undefined;

    const heightValue =
      typeof Number(user.height) === 'number'
        ? isInches
          ? Number(user.height)
          : convertCmToInches(Number(user.height))
        : 0;
    const weightValue =
      typeof Number(user.weight) === 'number'
        ? isLbs
          ? Number(user.weight)
          : convertKgToLb(Number(user.weight))
        : 0;

    let { partnerData }: any = userData;

    if (partnerData) {
      partnerData = {
        ...partnerData,
        medicalClearance: user.medicalClearance,
      };
    }

    editUserProfile(
      user.firstName ?? '',
      user.lastName ?? '',
      user.gender ?? '',
      dateOfBirthValue,
      user.familyStatus ?? '',
      user.location ?? '',
      user.dietaryPreferences ?? '',
      user.occupation ?? '',
      heightValue,
      weightValue,
      user.otherMemorableInfo ?? '',
      user.healthGoals ?? '',
      !!user.paymentIsPaid,
      user.paymentStatus ?? '',
      user.paymentEmail ?? '',
      user.paymentReferralUrl?.trim() ?? '',
      !!user.paymentReferralEnabled,
      user.paymentReferralCurrency ?? '',
      !!newWasShow,
      user?.pregnancy,
      user?.stressLevel,
      user?.physicalActivity,
      user?.proteinsBreakfast,
      user?.proteinsLunches,
      user?.simpleCarbs,
      user?.foodCravings,
      Number(user.diabetes),
      isGlp1drugs,
      partnerData,
      user?.partnerReferralUid !== '' ? user?.partnerReferralUid : undefined,
      featureFlags,
      cancellationDate,
      isCallCompleted,
      user?.cancellationPauseRequest,
      user?.cancellationPauseOutcome,
      isCoachSentRequest,
      isTestAccount,
    ).then(() => {
      dispatch(fetchCustomerById(userId));
      toggleEditingMode();
      toast.success('User profile data were updated');
    });
  };

  return (
    <FormProvider {...userForm}>
      <Container id="personal-information" onSubmit={userForm.handleSubmit(onSubmit)}>
        <MainBlock>
          <KeyInfo isLbs={isLbs} setIsLbs={setIsLbs} />
          <OnboardingInfo isInches={isInches} setIsInches={setIsInches} />
          <OtherInfo />
          <PaymentInfo />
          <ReferralInfo
            isCallCompleted={isCoachSentRequest}
            setCallCompleted={setIsCoachSentRequest}
          />
          <EmailInfo />
          <VideoIntroCall isCallCompleted={isCallCompleted} setCallCompleted={setIsCallCompleted} />
          <PartnerDataInfo
            isGlp1={isGlp1drugs}
            setIsGlp1={setIsGlp1drugs}
            partnerData={userData?.partnerData}
          />
          <PartnerReferralDataInfo />
          <FeatureFlagsInfo featureFlags={featureFlags} setFeatureFlags={setFeatureFlags} />
          <FitmateInfo setIsTestAccount={setIsTestAccount} isTestAccount={isTestAccount} />
        </MainBlock>

        <BottomBlock>
          <StyledButton onClick={toggleEditingMode} variant="outlined">
            Cancel
          </StyledButton>
          <ButtonSubmit type="submit">Save</ButtonSubmit>
        </BottomBlock>
      </Container>
    </FormProvider>
  );
};

export default ChatUserProfileEdit;
