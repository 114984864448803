import { createSlice } from '@reduxjs/toolkit';
import { IMessageQuoted } from 'types';

interface FutureMessage {
  id: string;
  message: string;
  type: 'feedback' | 'social' | 'badge';
}

interface SendMessageState extends IMessageQuoted {
  forceFeedbackTool: boolean;
  loading: boolean;
  futureMessages: FutureMessage[];
}

const initialState: SendMessageState = {
  actionTracking: undefined,
  forceFeedbackTool: false,
  loading: false,
  futureMessages: [],
};

export const sendMessageSlice = createSlice({
  name: 'sendMessageSlice',
  initialState,
  reducers: {
    enableFeedbackTool: (state) => {
      state.forceFeedbackTool = true;
    },
    disableFeedbackTool: (state) => {
      state.forceFeedbackTool = false;
    },
    setFutureMessages: (state, action) => {
      state.futureMessages = action.payload;
    },
    resetSendMessage: (state) => {
      state.futureMessages = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { enableFeedbackTool, disableFeedbackTool, setFutureMessages, resetSendMessage } =
  sendMessageSlice.actions;

export default sendMessageSlice.reducer;
