import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from 'store';
import { db } from 'utils/firebase/firebaseInit';
import { IFirestoresChatMessage } from 'types';
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
  startAfter,
  getCountFromServer,
} from 'firebase/firestore';
import { Message } from '@fitmate-coach/fitmate-types';

export type TChatSliceData = {
  docs: IFirestoresChatMessage[];
  loading: boolean;
  end: boolean;
  count: number;
  lastMonthMessages: Message[];
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    limit: 30,
    count: 0,
    data: {} as Record<string, TChatSliceData>,
    lastMonthMessages: [],
  },
  reducers: {
    chatNewMessages: (
      state,
      action: PayloadAction<{ userId: string; docs: IFirestoresChatMessage[] }>,
    ) => {
      if (!state.data[action.payload.userId]) return;
      const existing = state.data[action.payload.userId].docs;
      state.data[action.payload.userId].docs = [...existing, ...action.payload.docs];
    },
    chatUpdatedMessage: (
      state,
      action: PayloadAction<{ userId: string; doc: IFirestoresChatMessage }>,
    ) => {
      if (!state.data[action.payload.userId]) return;
      const existingIndex = state.data[action.payload.userId].docs.findIndex(
        (x) => x.id === action.payload.doc.id,
      );
      if (existingIndex === -1) return;
      state.data[action.payload.userId].docs[existingIndex] = action.payload.doc;
    },
  },
  extraReducers: (b) => {
    b.addCase(chatInit.pending, (state, action) => {
      if (!state.data[action.meta.arg]) {
        state.data[action.meta.arg] = {
          docs: [],
          loading: true,
          end: false,
          count: 0,
          lastMonthMessages: [],
        };
      }
      state.data[action.meta.arg].loading = true;
    });
    b.addCase(chatInit.fulfilled, (state, action) => {
      if (!action.payload) return;
      // Remove last message as it will be loaded by the snapshot
      action.payload.messages.shift();
      state.data[action.meta.arg].docs = action.payload.messages;
      state.data[action.meta.arg].loading = false;
      state.data[action.meta.arg].count = action.payload.count;
    });
    b.addCase(chatRefresh.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.data[action.meta.arg].docs = action.payload.docs.filter(
        (x: IFirestoresChatMessage) => x?.surveyData?.status !== 'in_progress',
      );
    });
    b.addCase(chatLoadOlder.pending, (state, action) => {
      if (!state.data[action.meta.arg]) return;
      state.data[action.meta.arg].loading = true;
    });
    b.addCase(chatLoadOlder.fulfilled, (state, action) => {
      if (!action.payload) return;
      const existing = state.data[action.meta.arg].docs;
      const next = action.payload
        .filter(
          (doc: IFirestoresChatMessage) =>
            !existing.some((existingDoc) => existingDoc.id === doc.id),
        )
        .filter((doc: IFirestoresChatMessage) => doc?.surveyData?.status !== 'in_progress');
      state.data[action.meta.arg].docs = [...next, ...existing];
      state.data[action.meta.arg].loading = false;
      state.data[action.meta.arg].end = action.payload.length < 1000;
    });
    b.addCase(getLastMonthMessages.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.lastMonthMessages = action.payload;
    });
  },
});

export const { chatNewMessages, chatUpdatedMessage } = chatSlice.actions;
export default chatSlice.reducer;

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
}>();

export const chatRefresh = createAppAsyncThunk<any, string>(
  'chat/refresh',
  async (userId, store) => {
    const state = store.getState().chat;
    return { docs: await getMessages(userId, state.data[userId].docs.length) };
  },
);

export const chatInit = createAppAsyncThunk<any, string>('chat/init', async (userId, store) => {
  const state = store.getState().chat;

  const count = await getMessagesCount(userId);

  return { count, messages: await getMessages(userId, state.limit) };
});

export const chatLoadOlder = createAppAsyncThunk<any, string>(
  'chat/load-more',
  async (userId, store) => {
    const state = store.getState().chat;
    const current = state.data[userId] as TChatSliceData | undefined;
    const docs = current?.docs;
    const oldest = docs ? docs[0] : undefined;
    if (!current || !oldest || current.end) return;
    return getMessages(userId, 1000, oldest);
  },
);

function getMessagesQuery(userId: string, max?: number, from?: any) {
  let q = query(
    collection(db, 'messages'),
    where('userId', '==', userId),
    where('isDeleted', '==', false),
    orderBy('createdAt', 'desc'),
  );

  if (from && max) {
    q = query(q, startAfter(from), limit(max));
  }

  return q;
}

export const getMessages = async (userId: string, max: number, from?: any) => {
  try {
    let q = getMessagesQuery(userId, max, from);

    q = query(q, limit(max));

    const docSnap = await getDocs(q);
    return docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  } catch (e) {
    console.log('error on getMessagesQuery', e);
  }
};

export const getMessagesCount = async (userId: string) => {
  try {
    const q = getMessagesQuery(userId);

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  } catch (e) {
    console.log('error on getMessagesCount', e);
  }
};

export const getLastMonthMessages = createAppAsyncThunk<any, string>(
  'chat/last-month',
  async (userId, store) => {
    const state = store.getState().chat;
    const q = query(
      collection(db, 'messages'),
      where('userId', '==', userId),
      where('createdAt', '>=', new Date(new Date().setDate(new Date().getDate() - 30))),
      orderBy('createdAt', 'desc'),
    );
    const docSnap = await getDocs(q);
    return docSnap.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .filter(
        (message: any) =>
          message.customerCanSee === undefined || message.userRoleFrom !== 'chat_bot',
      );
  },
);
