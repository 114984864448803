import { SelectBlock, StyledSelect } from '../styles';
import Wrapper from '../Wrapper';
import { FormControl, InputLabel } from '@mui/material';
import { EFeatureSelectedType, ETestAccountType } from 'types';
import SelectOptionsWrapper from 'common/core/SelectOptionsWrapper';
import { YesNoList } from 'utils/data/question';

const FitmateInfo = ({
  isTestAccount,
  setIsTestAccount,
}: {
  isTestAccount: boolean;
  setIsTestAccount: (val: boolean) => void;
}) => {
  return (
    <Wrapper blockTitle="Fitmate">
      <SelectBlock>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Is a fitmate test account</InputLabel>
          <StyledSelect
            // variant="standard"
            native
            autoWidth
            value={isTestAccount ? EFeatureSelectedType.enabled : EFeatureSelectedType.disabled}
            onChange={({ target }) => {
              console.log(target, target.value === ETestAccountType.yes);
              setIsTestAccount(target.value === EFeatureSelectedType.enabled);
            }}
            label="Is a fitmate test account"
          >
            <SelectOptionsWrapper items={YesNoList} />
          </StyledSelect>
        </FormControl>
      </SelectBlock>
    </Wrapper>
  );
};

export default FitmateInfo;
