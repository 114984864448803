import SurveysContent from './SurveysContent';
import { useAdminSurveys } from 'hooks/admin/useAdminSurveys';
import { LeftColumnWrapper, Wrapper } from './styles';
import { ISurveyTemplate, ESurveyType } from 'types';

const SurveysContainer = () => {
  const { surveysTemplateList } = useAdminSurveys();

  return (
    <Wrapper maxWidth="xl">
      <LeftColumnWrapper>
        <SurveysContent
          title="Onboarding"
          buttonTitle="Create new template"
          list={surveysTemplateList.filter(
            (item: ISurveyTemplate) =>
              item.onboardingType === ESurveyType.ONBOARDING ||
              item.onboardingType === ESurveyType.ONBOARDING_ACTIVE ||
              item.onboardingType === ESurveyType.END_ONBOARDING_ACTIVE ||
              item.onboardingType === ESurveyType.PRE_ONBOARDING_ACTIVE,
          )}
          type={ESurveyType.ONBOARDING}
        />
        {/*<SurveysContent*/}
        {/*  title="Templates"*/}
        {/*  buttonTitle="Create new template"*/}
        {/*  list={surveysTemplateList.filter(*/}
        {/*    (item: ISurveyTemplate) => !item.actionId && !item.onboardingType,*/}
        {/*  )}*/}
        {/*  type={ESurveyType.REGULAR}*/}
        {/*/>*/}
      </LeftColumnWrapper>
    </Wrapper>
  );
};

export default SurveysContainer;
