/* eslint-disable @typescript-eslint/ban-ts-comment */
import { EFeatureFlagsType, EIconType, IUserDataList, toTimestamp } from 'types';
import { convertLbToKg, convertInchesAdvanced, convertInchesToCm } from 'utils/format/measures';
import { getUserStatus } from 'utils/data/payment';
import { ClientUser, TFireBaseUser } from '@fitmate-coach/fitmate-types';
import { formatDateTime, calculateAge, getDateFromUnix } from 'utils/format/datetime';
import { format } from 'date-fns';
import { FeatureFlags } from 'utils/data/flag';
import {
  EFoodCravingsType,
  EPhysicalActivityType,
  EProteinsBreakfastType,
  EProteinsLunchesType,
  ESimpleCarbsType,
  EStressLevelType,
} from '@fitmate-coach/fitmate-types';

const formatPaymentPlanText = (userData: ClientUser) => {
  if (userData && userData.billingData) {
    return `${userData?.billingData?.paymentPlan} month(s)`;
  }
};

export const getGeneralInfo = (userData: TFireBaseUser | null): IUserDataList[] => [
  { title: 'Sex', value: userData?.gender },
  {
    title: 'Starting weight (lbs)',
    value: userData?.weight
      ? `${userData?.weight} lbs (${convertLbToKg(userData?.weight).toFixed(0)} kg)`
      : null,
  },
];

export const getOnboardingInfo = (userData: TFireBaseUser | null): IUserDataList[] => [
  {
    title: 'Date of Birth ',
    value: userData?.dateOfBirth
      ? `${formatDateTime(userData?.dateOfBirth)} (${calculateAge(userData?.dateOfBirth)} years)`
      : null,
  },
  {
    title: 'Height (in)',
    value: userData?.height
      ? `${convertInchesAdvanced(userData?.height)} (${convertInchesToCm(userData?.height).toFixed(
          0,
        )} cm)`
      : null,
  },
  {
    title: 'Pregnancy status',
    value: userData?.pregnancy === false ? 'No' : userData?.pregnancy ? 'Yes' : null,
  },
  {
    title: 'Stress levels',
    value:
      typeof userData?.lifestyle?.stressLevel === 'number'
        ? Object.values(EStressLevelType)[userData.lifestyle.stressLevel]
        : null,
  },
  {
    title: 'Physical activity (hrs / week)',
    value:
      typeof userData?.lifestyle?.physicalActivity === 'number'
        ? Object.values(EPhysicalActivityType)[userData.lifestyle.physicalActivity]
        : null,
  },
  {
    title: 'Lean protein at breakfast',
    value:
      typeof userData?.dietaryHabits?.proteinsBreakfast === 'number'
        ? Object.values(EProteinsBreakfastType)[userData.dietaryHabits.proteinsBreakfast]
        : null,
  },
  {
    title: 'Lean protein (lunch & dinner)',
    value:
      typeof userData?.dietaryHabits?.proteinsLunches === 'number'
        ? Object.values(EProteinsLunchesType)[userData.dietaryHabits.proteinsLunches]
        : null,
  },
  {
    title: 'Simple carbs (lunch & dinner)',
    value:
      typeof userData?.dietaryHabits?.simpleCarbs === 'number'
        ? Object.values(ESimpleCarbsType)[userData.dietaryHabits.simpleCarbs]
        : null,
  },
  {
    title: 'Food cravings',
    value:
      typeof userData?.dietaryHabits?.foodCravings === 'number'
        ? Object.values(EFoodCravingsType)[userData.dietaryHabits.foodCravings]
        : null,
  },
];

export const getOtherInfo = (userData: ClientUser | null): IUserDataList[] => [
  { title: 'Family status', value: userData?.familyStatus },
  { title: 'Location', value: userData?.location },
  { title: 'Timezone', value: userData?.timezone },
  { title: 'Dietary preferences', value: userData?.dietaryPreferences },
  { title: 'Occupation', value: userData?.occupation },
  { title: 'Other memorable info', value: userData?.otherMemorableInfo },
  { title: 'Health Goals', value: userData?.healthGoals },
];

export const getReferralInfo = (userData: ClientUser | null): IUserDataList[] => {
  return [
    { title: 'Referral link', value: userData?.billingData?.referralLink?.url },
    {
      title: 'Currency for refer a friend',
      value: userData?.billingData?.referralLink?.currency === 'GBP' ? 'UK (£)' : ' US ($)',
    },
    {
      title: 'Did coach send a referral request?',
      value: userData?.referralRequest?.coachSentRequest ? 'Yes' : 'No',
    },
    {
      title: 'Date coach sent a referral request',
      value: userData?.referralRequest?.coachRequestDate
        ? format(userData?.referralRequest?.coachRequestDate.toDate(), 'M/d/yyyy')
        : '-',
    },
  ];
};

export const getPaymentInfo = (userData: ClientUser | null): IUserDataList[] => {
  const userStatus = getUserStatus(
    userData?.billingData?.status,
    userData?.billingData?.isPaid,
    userData?.billingData?.isReferralLinkEnabled,
  );

  let basicList = [
    { title: 'User status', value: userStatus?.labelShort },
    { title: 'Payment status', value: userData?.billingData?.subscriptionStatus },
    { title: 'Payment email', value: userData?.billingData?.email, icon: EIconType.payment },
    {
      title: 'Date of the account creation',
      value: userData?.createdAt ? getDateFromUnix(toTimestamp(userData?.createdAt).seconds) : null,
    },
    {
      title: 'Stripe customer ID',
      value: userData?.billingData?.stripeCustomerId,
      webLink: `https://dashboard.stripe.com/customers/${userData?.billingData?.stripeCustomerId}`,
      showButton: Boolean(!userData?.billingData?.stripeCustomerId),
    },
  ];

  if (userData?.billingData?.cancellationDate) {
    basicList = [
      ...basicList,
      {
        title: 'Subscription cancellation date (last day of service)',
        value: userData?.billingData?.cancellationDate
          ? format(userData?.billingData?.cancellationDate.toDate(), 'd LLL yyyy')
          : null,
      },
    ];
  }

  if (userData?.partner) {
    basicList = [
      // @ts-ignore
      ...basicList,
      // @ts-ignore
      { title: 'Paid by partner', value: userData?.billingData?.isPaidByPartner ? 'Yes' : 'No' },
      {
        title: 'Subscription start date',
        // @ts-ignore
        value: userData?.billingData?.planStartDate
          ? userData?.billingData?.planStartDate.toDate().toLocaleDateString('en-GB')
          : null,
      },
      { title: 'Subscription status', value: userData?.billingData?.subscriptionStatus },
      // @ts-ignore
      {
        title: 'Subscription type',
        value: formatPaymentPlanText(userData),
      },
    ];

    if (userData?.billingData?.subscriptionPauseStartDate) {
      basicList = [
        ...basicList,
        {
          title: 'Subscription pause start date',
          value: userData?.billingData?.subscriptionPauseStartDate
            ? userData?.billingData?.subscriptionPauseStartDate.toDate().toLocaleDateString('en-GB')
            : null,
        },
        {
          title: 'Subscription pause end date',
          value: userData?.billingData?.subscriptionPauseEndDate
            ? userData?.billingData?.subscriptionPauseEndDate.toDate().toLocaleDateString('en-GB')
            : null,
        },
      ];
    }
  }

  if (userData?.cancellationPauseRequest) {
    basicList = [
      ...basicList,
      {
        title: 'Cancel/pause request',
        value: userData?.cancellationPauseRequest,
      },
    ];
  }

  if (userData?.cancellationPauseOutcome) {
    basicList = [
      ...basicList,
      {
        title: 'Cancel/pause outcome',
        value: userData?.cancellationPauseOutcome,
      },
    ];
  }

  return basicList;
};

export const getAccountEmailInfo = (userData: TFireBaseUser | null): IUserDataList[] => [
  { title: 'Account ID', value: userData?.id, icon: EIconType.identifier },
  { title: 'Mobile App version', value: userData?.appVersion, icon: EIconType.appVersion },
  { title: 'Personal email', value: userData?.email, icon: EIconType.email },
  {
    title: 'Mobile phone',
    value: userData?.phoneNumber ?? userData?.phone,
    icon: EIconType.appVersion,
  },
];

export const getMedicalInfo = (userData: TFireBaseUser | null): IUserDataList[] => {
  const formatDiabete = (diabetes: number | undefined) => {
    switch (diabetes) {
      default:
      case 0:
        return 'No diabetes';
      case 1:
        return 'Pre-diabetes';
      case 2:
        return 'Type 1 diabetes';
      case 3:
        return 'Type 2 diabetes';
    }
  };

  const formatGLP1 = (glp1: boolean | undefined) => {
    return glp1 ? 'Yes' : 'No';
  };

  return [
    { title: 'Diabetes', value: formatDiabete(userData?.diabetes) },
    { title: 'GLP-1 drugs', value: formatGLP1(userData?.glp1drugs) },
  ];
};

export const getPartnerInfos = (userData: TFireBaseUser | null): IUserDataList[] => {
  // @TODO: add a partner name matcher mechanism
  if (!userData?.partnerData) return [];

  let infos =
    userData?.partnerData &&
    Object.keys(userData?.partnerData).map((key) => {
      return {
        title: key,
        // @ts-ignore
        value: userData?.partnerData[key],
      };
    });

  if (userData?.metadata) {
    const metadatas = Object.keys(userData?.metadata).map((key) => {
      return {
        title: key,
        // @ts-ignore
        value: userData?.metadata[key],
      };
    });

    infos = [...infos, ...metadatas];
  }

  infos = [
    ...infos,
    {
      title: 'Last update',
      value: userData?.updatedAt ? getDateFromUnix(toTimestamp(userData?.updatedAt).seconds) : null,
    },
  ];

  return infos;
};

export const getPartnerReferralInfos = (userData: TFireBaseUser): IUserDataList[] => {
  return [
    {
      title: 'Partner name',
      value: `${userData.firstName} ${userData.lastName}`,
    },
  ];
};

export const getFeatureFlagsInfo = (userData: TFireBaseUser | null): IUserDataList[] => {
  return (
    // IDEA: In display view, we might need to list all flags (UX choice),
    // In that case, we'll have to iterate `FeatureFlags` first, instead of
    // `userData?.featureFlags` and show '❌' accordingly by comparing.
    userData?.featureFlags?.map((featureFlag) => {
      return {
        // NOTE: We assume we have a correctly typed flag… might do additional type-checks?
        // Also the `String` assertion looks redundant, but we could have an `unknown`
        ...FeatureFlags[String(featureFlag) as keyof typeof FeatureFlags],
        // NOTE: Since we're only showing assigned flags…
        value: '✅',
        icon: EIconType.featureFlag,
      };
    }) || []
  );
};

export const getIntroCall = (userData: TFireBaseUser | null): IUserDataList[] => {
  return [
    {
      title: 'Completed intro call',
      value: userData?.introCallCompleted ? 'Yes' : 'No',
    },
  ];
};
