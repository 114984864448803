import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from 'utils/firebase/firebaseInit';
import { IAdminUser } from 'types';
import { fetchCustomerById } from './customerInfosSlice';

export interface ICustomersState {
  pendingCustomers: IAdminUser[];
  loading: boolean;
  teamToAssign?: any;
  customerAssigned?: string;
}

const initialState: ICustomersState = {
  pendingCustomers: [] as IAdminUser[],
  loading: true,
};

export const fetchCustomersWithoutCoach = createAsyncThunk(
  'customers/fetchCustomersWithoutCoach',
  async () => {
    const q = query(
      collection(db, 'users'),
      where('role', '==', 'user'),
      where('coachAssignment.hasCoachesAssigned', '==', false),
    );

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => {
      const data = doc.data();

      return {
        id: doc.id,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        avatarUrl: data.avatarUrl,
        role: data.role,
        timezone: data.timezone,
        createdAt: data.createdAt,
        coachAssignment: data.coachAssignment,
        partner: data.partner,
        diabetes: data.diabetes,
        glp1drugs: data.glp1drugs,
        partnerData: data.partnerData,
      };
    });
  },
);

export const assignTeamToCustomer = createAsyncThunk(
  'customers/assignTeamToCustomer',
  async (args: { userId: string }, { getState }) => {
    const state = getState() as any;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { userId } = args;
    const { teamToAssign } = state.customers;
    const userRef = doc(db, 'users', userId);

    const { supportiveCoaches } = teamToAssign;
    let coaches = {};
    supportiveCoaches.map((coach: any): any => {
      coaches = {
        ...coaches,
        [coach.id]: {
          isMainCoach: false,
        },
      };
    });

    await updateDoc(userRef, {
      coachAssignment: {
        hasCoachesAssigned: true,
        team: teamToAssign.id,
        coaches: {
          [teamToAssign.mainCoach.id]: {
            isMainCoach: true,
          },
          ...coaches,
        },
      },
    });
  },
);

export const updateWeighCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async (
    args: { userId: string; frequency?: string; frequencyNotes?: string },
    { getState, dispatch },
  ) => {
    const state = getState() as any;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { userId, frequency, frequencyNotes } = args;
    const userRef = doc(db, 'users', userId);

    await updateDoc(userRef, {
      ...(frequency && { weighInFrequency: frequency }),
      ...(frequencyNotes && { weighInFrequencyNotes: frequencyNotes }),
    });
    dispatch(fetchCustomerById(userId));
  },
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    prepareTeamAssignment: (state, action) => {
      state.teamToAssign = action.payload.team;
      state.customerAssigned = action.payload.userId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersWithoutCoach.fulfilled, (state, action) => {
      state.loading = true;
      state.pendingCustomers = action.payload;
    });
    builder.addCase(assignTeamToCustomer.fulfilled, (state) => {
      state.customerAssigned = undefined;
      state.teamToAssign = undefined;
    });
  },
});

// Action creators are generated for each case reducer function
export const { prepareTeamAssignment } = customersSlice.actions;

export default customersSlice.reducer;
