export interface IPromptMessage {
  role: string;
  content: string;
}

export interface IProgramActionsInterface {
  shareMessage: string;
  loading: boolean;
  messages: IPromptMessage[];
  feedbackLaunched?: boolean;
  errorMsg?: string;
  currentModel?: string;
  currentTemperature?: number;
  canShare?: boolean;
  currentMessageId?: string;
  currentActionId?: string;
  currentTrackingId?: string;
  disableShare: boolean;
  shouldAddShareMessage: boolean;
  step: number;
}

export interface ITask {
  id: {
    develop: number;
    stage: number;
    main: number;
    [key: string]: number;
  };
  name: EGeneralListEnums | EClientListEnums;
}

export enum EGeneralListEnums {
  ON_A_CALL = 'On a call',
  EDIT_CHECK_USER_INFO = 'Editing/Checking user info sheet',
  NO_APP = 'Handling client who has not downloaded app yet',
  CREATE_TODO_LIST = 'Create daily to-do list',
  CHECK_HANDOVER = 'Check handover notes/comments',
  WRITE_HANDOVER = 'Write handover notes/comments',
  CHECK_EMAILS = 'Check emails',
  SLACK = 'Send/Check Slack',
  CHECK_SALES_MSG = 'Check Salesmsg',
  CHECK_AIRTABLE = 'Check Airtable',
  CHECK_PAYMENT = 'Check Stripe / Paypal',
  CHECK_EDIT = 'Check/Edit follow up or delivery checklist',
  BRAKE = 'Take break',
  OTHER = 'Other',
}

export enum EClientListEnums {
  MANUAL_ONBOARDING = 'Manual onboarding process (adding new client into User Info Sheet and Coach app, checking Stripe, checking airtable etc)',
  ONBOARDING_MESSAGES = 'Onboarding messages (everything from first message until just before sending program)',
  UPDATE_PROGRAM = 'Update program in the Coach app (e.g. adding actions to their program)',
  PROGRAM = 'Program (create and send)',
  REMINDERS = 'Reminders (create and send)',
  PROMPTS = 'Prompts (create and send)',
  FEEDBACK = 'Feedback on assignment (create and send, using tool)',
  ANSWERING = 'Answering question (create and send, using tool or guidelines)',
  SETBACKS = 'Setbacks discussion (create and send)',
  END_OF_WEEK_SURVEY = 'End of week survey (create and send, using tool)',
  MONTH_OFFER = '4 month offer (create and send)',
  EDITING_CHECK_INFO = 'Editing/Checking user info sheet. General',
  CLIENT_CANCELLATION = 'Client cancellation (e.g. sending message, cancellation survey, updating Stripe etc)',
  OTHER = 'Other (e.g. contact a non-responsive client, custom follow up messages, email, salesmsg etc)',
  END_OF_WEEK_REVIEW = 'End of week Review',
}
