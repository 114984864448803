import { useAppSelector } from '../index';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../../utils/firebase/firebaseInit';

const useChatAddMessage = () => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const sendAs = useAppSelector((state) => state.sendAs.selected);

  const createMessage = async (message: string) => {
    const body = message ? { body: message } : {};
    const userAvatarUrlFrom = coachData?.avatarUrl
      ? { userAvatarUrlFrom: coachData?.avatarUrl }
      : {};
    const virtualUser =
      sendAs?.id !== coachData?.id
        ? {
            virtualUserIdFrom: sendAs?.id ?? '',
            virtualUserNameFrom: sendAs?.firstName ?? '',
            virtualUserAvatarUrlFrom: sendAs?.avatarUrl ?? '',
          }
        : {};

    const details = {
      userId: customer?.id,
      userIdFrom: coachData?.id,
      userNameFrom: coachData?.displayName ?? coachData?.firstName + ' ' + coachData?.lastName,
      ...userAvatarUrlFrom,
      ...body,
      files: [],
      createdAt: Timestamp.now(),
      isViewed: false,
      isDeleted: false,
      ...virtualUser,
    };

    await addDoc(collection(db, 'messages'), details);
  };

  return {
    createMessage,
  };
};

export default useChatAddMessage;
