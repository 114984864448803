import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from 'common/core/Button';
import { getDocs, query, where } from '@firebase/firestore';
import { collection, limit, orderBy } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getCompletion,
  getMealFeedback,
  setCurrentActionId,
  setCurrentMessageId,
  setCurrentTrackingId,
  verifyProgressEligibleForBadge,
} from 'store/automation/aiSlice';
import { attachCategory, fetchCategory } from 'store/program/weekProgramSlice';
import { db } from 'utils/firebase/firebaseInit';
import { getGlp1AndDiabetes, getWeekNumberProgram } from 'utils/data/user';
import { StyledFormControl, StyledFormText } from '../DrawerFeedbackProgress/styles';
import { getFocusAreas } from 'store/program/focusAreaSlice';
import { enableFeedbackTool } from 'store/chat/sendMessageSlice';
import {
  closeDrawer,
  resetFeedback,
  resetFeedbackType,
  resetIsVoiceNote,
  setFeedbackType,
  setIsVoiceNote,
} from 'store/customers/progressFeedbackSlice';
import axios from 'axios';
import { toast } from 'sonner';
import { useChatCreateMessage } from 'hooks/chat/useChatCreateMessage';
import { fetchTeams } from 'store/coaches/coachesSlice';
import { clearQuotedMessage } from 'store/chat/quotedMessageSlice';
import { getLastMonthMessages } from 'store/chat';
import { getCurrentWeekProgramActions } from 'store/program/programActionsSlice';

const ActionFeedbackForm = () => {
  const dispatch = useAppDispatch();
  const { focusAreas } = useAppSelector((state) => state.focusArea);
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { message, actionId, feedbackType, isVoiceNote, voiceNoteMessage } = useAppSelector(
    (state) => state.progressFeedback,
  );
  const { programAction, programActions } = useAppSelector((state) => state.programActions);
  const { weeks, isNutrition, category, currentWeekPlan } = useAppSelector(
    (state) => state.weekProgram,
  );
  const { loading, feedbackLaunched, errorMsg, step } = useAppSelector(
    (state) => state.automationAi,
  );
  const teams = useAppSelector((state) => state.coaches.teams);

  const [additionalDetails, setAdditionalDetails] = useState('');
  const [theVoiceNoteMessage, setTheVoiceNoteMessage] = useState('');
  const [loadingNote, setLoadingNote] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [voiceNote, setVoiceNote] = useState();
  const [extraInfos, setExtraInfos] = useState('');
  const [nextDayTarget, setNextDayTarget] = useState('');
  const [nextDayDetails, setNextDayDetails] = useState('');
  const [mealSize, setMealSize] = useState('about right');
  const [isNew, setIsNew] = useState(false);
  const createMessage = useChatCreateMessage();

  useEffect(() => {
    if (!focusAreas || !focusAreas.length) {
      dispatch(getFocusAreas());
    }
  }, [focusAreas, dispatch]);

  useEffect(() => {
    if (!teams || !teams.length) {
      dispatch(fetchTeams());
    }
  }, [teams, dispatch]);

  useEffect(() => {
    if (message && actionId) {
      setAdditionalDetails(message.actionTracking?.details ?? message.body);
    }
  }, [actionId, message]);

  useEffect(() => {
    if (voiceNoteMessage && isVoiceNote) {
      setTheVoiceNoteMessage(voiceNoteMessage);
    }
  }, [voiceNoteMessage, isVoiceNote]);

  useEffect(() => {
    if (programAction?.categoryId) {
      const { categoryId } = programAction;
      if (categoryId) {
        dispatch(fetchCategory({ categoryId }));
      }
    }
    if (!programAction?.categoryId && focusAreas && focusAreas?.length > 0 && currentWeekPlan) {
      dispatch(attachCategory({ programAction, focusAreas, currentWeekPlan }));
    }
  }, [dispatch, programAction, focusAreas, currentWeekPlan]);

  useEffect(() => {
    dispatch(resetFeedbackType());
    if (category && programAction) {
      const {
        published: { status },
      } = programAction ?? {};
      let newOrExisting = '';
      newOrExisting = status === 'new' ? 'New goal' : 'Existing goal';
      const mealsOrNonmeals = isNutrition ? 'meals' : 'nonmeals';
      dispatch(setFeedbackType(`${newOrExisting} - ${mealsOrNonmeals}`));
      setIsNew(status === 'new');
    }
  }, [message?.id, category, isNutrition, programAction]);

  useEffect(() => {
    dispatch(resetIsVoiceNote());
    if (!currentWeekPlan || !programActions || !programAction || !teams) return;
    const voiceCoaches = teams.filter(
      (team) => team.elevenLabsApiKey !== '' && team.elevenLabsApiKey !== undefined,
    );
    const coachTeamDoesVoiceNote = voiceCoaches.find(
      (team) => team.id === customer?.coachAssignment?.team,
    );
    if (!coachTeamDoesVoiceNote) return;
    const focusAreasToIgnore = [
      'Getting social support',
      'Lean protein-rich breakfast',
      'Protein or fiber-rich snacks',
      'Fiber & lean protein-rich lunches and dinners',
      'Being in control of daily calorie intake',
    ];
    const weekPlanAreaIds = currentWeekPlan.published.plan
      .filter((x: any) => !focusAreasToIgnore.includes(x.title))
      .map((p: any) => p.id);

    if (currentWeekPlan.week === 1 || currentWeekPlan.week === 2) {
      const trackedNonMealGoals = programActions.filter(
        (action: any) =>
          weekPlanAreaIds.includes(action.planAreaId) &&
          action.lastTracking &&
          action.lastTracking.currentDay > 0,
      );
      trackedNonMealGoals.sort(
        (a: any, b: any) => a.lastTracking.createdAt - b.lastTracking.createdAt,
      );
      const isFirstTracked = trackedNonMealGoals?.[0]?.id === programAction?.id;

      if (isFirstTracked) {
        dispatch(setIsVoiceNote(true));
        return;
      }
      const completedNonMealGoals = programActions.filter(
        (action: any) =>
          weekPlanAreaIds.includes(action.planAreaId) &&
          action.lastTracking &&
          action.lastTracking.currentDay >= action.lastTracking.daysPerWeek,
      );
      completedNonMealGoals.sort(
        (a: any, b: any) => a.lastTracking.createdAt - b.lastTracking.createdAt,
      );
      const isFirstCompleted = completedNonMealGoals?.[0]?.id === programAction?.id;
      if (isFirstCompleted) {
        dispatch(setIsVoiceNote(true));
        return;
      }
    } else if (currentWeekPlan.week > 2) {
      const completedNonMealGoals = programActions.filter(
        (action: any) =>
          weekPlanAreaIds.includes(action.planAreaId) &&
          action.lastTracking &&
          action.lastTracking.currentDay >= action.lastTracking.daysPerWeek,
      );
      completedNonMealGoals.sort(
        (a: any, b: any) => a.lastTracking.createdAt - b.lastTracking.createdAt,
      );
      const isFirstCompleted = completedNonMealGoals?.[0]?.id === programAction?.id;
      if (isFirstCompleted) {
        dispatch(setIsVoiceNote(true));
        return;
      }
    }
  }, [message?.id, programActions, programAction, currentWeekPlan, teams]);

  useEffect(() => {
    if (step === 2 && !isVoiceNote) {
      dispatch(
        verifyProgressEligibleForBadge({
          hasPictureAttached: message.actionTracking?.files?.length > 0 ? 'yes' : 'no',
        }),
      );
    }
  }, [step]);

  useEffect(() => {
    if (currentWeekPlan && customer) {
      dispatch(getCurrentWeekProgramActions({ userId: customer?.id!, planId: currentWeekPlan.id }));
    }
  }, [currentWeekPlan, customer]);

  const handleGenerateVoiceNote = async () => {
    if (customer && theVoiceNoteMessage) {
      try {
        setLoadingNote(true);
        setVoiceNote(undefined);
        const res = await axios({
          method: 'POST',
          url: process.env.REACT_APP_VOICENOTE_API_URL,
          data: {
            text: theVoiceNoteMessage,
            userId: customer.id,
          },
        });
        setVoiceNote(res.data.url);
      } catch (e) {
        toast.error('Failed to generate voice note');
      } finally {
        setLoadingNote(false);
      }
    }
  };

  const handleSendVoiceNote = async () => {
    setLoadingSend(true);
    if (!customer || !teams || !voiceNote) return;
    const team = teams.find((t) => t.id === customer.coachAssignment?.team);
    if (!team) {
      throw new Error('Team not found');
    }
    try {
      await createMessage(
        '',
        [
          {
            size: 10000,
            name: 'vocal message',
            thumbnailUrl: '',
            type: 'audio/mpeg',
            url: voiceNote,
          },
        ],
        [],
      );
      toast.success('Voice note sent');
      setLoadingSend(false);
      dispatch(closeDrawer());
      dispatch(clearQuotedMessage());
      dispatch(resetFeedback());
    } catch (e) {
      console.log(e);
      toast.error('Failed to send voice note');
    }
  };

  const handleLaunchProgressFeedback = async () => {
    if (customer && programAction && weeks) {
      try {
        const ref = collection(db, 'messages');
        const querySnap = await getDocs(
          query(
            ref,
            where('replyToMessage.actionTracking.actionId', '==', actionId),
            where('userId', '==', customer.id),
            orderBy('createdAt', 'desc'),
            limit(5),
          ),
        );
        console.log('additioanDetails', additionalDetails);
        const recentAnswers = querySnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        dispatch(getLastMonthMessages(customer.id!));
        const generatedAnswers = recentAnswers.filter((answer: any) => answer.aiGeneratedMessage);
        const previousAnsers = generatedAnswers.map((ga: any) => ga.aiGeneratedMessage);
        const today = new Date();
        const payload = {
          client_name: customer.firstName,
          glp1_diabetes_status: getGlp1AndDiabetes(customer),
          weekNumber: getWeekNumberProgram(weeks, today),
          goalArea: programAction.published.goal,
          weekTarget: programAction.published.daysPerWeek,
          lastCount: programAction.lastTracking?.currentDay ?? 'not applicable',
          today,
          additionalDetails:
            additionalDetails && additionalDetails.length > 0
              ? additionalDetails
              : 'not applicable',
          extraInfos,
          nextDayTarget,
          nextDayDetails,
          mealSize,
          recentAnswers:
            previousAnsers.length > 0 ? previousAnsers.join('\n') : 'No previous answers',
          send_referral: customer.referralRequest?.coachSentRequest === true ? 'yes' : 'no',
          alreadyBadgedTracker: customer.haveEverReceivedBadge ? 'yes' : 'no',
          hasPictureAttached: message.actionTracking?.files?.length > 0 ? 'yes' : 'no',
          messageId: message.id,
          isNew,
          isNutrition,
          isVoiceNote,
        };
        console.log('payload', payload);
        dispatch(enableFeedbackTool());
        dispatch(setCurrentMessageId(message.id));
        dispatch(setCurrentActionId(message.actionTracking.actionId));
        dispatch(setCurrentTrackingId(message.actionTracking.documentId));
        if (isNutrition) {
          dispatch(getMealFeedback(payload));
        } else {
          dispatch(getCompletion(payload));
        }
      } catch (e) {
        console.log('error trying to generate feedback', e);
      }
    }
  };

  return (
    <Container>
      {errorMsg && <Alert severity="error">An error occurred: {errorMsg}</Alert>}

      {!feedbackLaunched && step === 1 && (
        <Card>
          <CardContent>
            <div>
              <p>
                Give feedback on this <b>{feedbackType}</b> goal
              </p>
              <StyledFormText>Here are the details given by the client :</StyledFormText>
              <TextField
                label="Details given by the client"
                multiline
                fullWidth
                value={additionalDetails}
                onChange={(e) => setAdditionalDetails(e.target.value)}
                variant="outlined"
                helperText="Coach to add any details about the progress"
              />

              <StyledFormText>
                You can describe the ingredients you see on the image or any other element you think
                is relevant to give a better feedback
              </StyledFormText>
              <TextField
                sx={{
                  margin: '10px 0',
                }}
                label="Extra infos"
                multiline
                fullWidth
                rows={2}
                variant="outlined"
                value={extraInfos}
                onChange={(e) => setExtraInfos(e.target.value)}
              />

              {feedbackType &&
                ['New goal - meals', 'New goal - nonmeals'].includes(feedbackType) && (
                  <>
                    <StyledFormText>
                      Coach to lookup on the program when is the next day for this goal
                    </StyledFormText>
                    <StyledFormControl variant="filled" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Customer next day to hit target
                      </InputLabel>
                      <Select
                        // variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={nextDayTarget}
                        onChange={(e) => setNextDayTarget(e.target.value as string)}
                      >
                        <MenuItem value="Monday">Monday</MenuItem>
                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                        <MenuItem value="Thursday">Thursday</MenuItem>
                        <MenuItem value="Friday">Friday</MenuItem>
                        <MenuItem value="Saturday">Saturday</MenuItem>
                        <MenuItem value="Sunday">Sunday</MenuItem>
                      </Select>
                      <FormHelperText>
                        Coach to find the info on either chat or program plan
                      </FormHelperText>
                    </StyledFormControl>
                  </>
                )}
              {isNutrition && (
                <>
                  <StyledFormText>
                    Any extra comment given by the client regarding his next time on this goal?
                  </StyledFormText>
                  <TextField
                    sx={{
                      margin: '10px 0',
                    }}
                    label="Details provided regarding that next day"
                    multiline
                    fullWidth
                    variant="outlined"
                    value={nextDayDetails}
                    onChange={(e) => setNextDayDetails(e.target.value)}
                  />
                </>
              )}
              {isNutrition && (
                <>
                  <StyledFormText>
                    You can change the meal size if you think this is not correct
                  </StyledFormText>
                  <StyledFormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-label">Meal size</InputLabel>
                    <Select
                      // variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mealSize}
                      onChange={(e) => setMealSize(e.target.value as string)}
                    >
                      <MenuItem value="about right">About right</MenuItem>
                      <MenuItem value="too small">Too small</MenuItem>
                      <MenuItem value="too big">Too big</MenuItem>
                    </Select>
                  </StyledFormControl>
                </>
              )}
            </div>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={handleLaunchProgressFeedback}>
              Start feedback
            </Button>
          </CardActions>
        </Card>
      )}
      {step === 2 && isVoiceNote && (
        <Card>
          <CardContent>
            <div>
              <StyledFormText>
                Here is the feedback that you should send as a voice note:
              </StyledFormText>
              <TextField
                label="Message to send"
                multiline
                fullWidth
                value={theVoiceNoteMessage}
                onChange={(e) => setTheVoiceNoteMessage(e.target.value)}
                variant="outlined"
                helperText="Coach to modify any details before sending"
              />
            </div>
            {voiceNote && (
              <div>
                <div className="flex flex-col w-full my-4">
                  <audio controls className="w-full">
                    <source src={voiceNote} type="audio/mp3" />
                  </audio>
                </div>
              </div>
            )}
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={handleGenerateVoiceNote} disabled={loadingNote}>
              Generate voice note
            </Button>
            <Button color="primary" onClick={handleSendVoiceNote} disabled={loadingSend}>
              Send voice note
            </Button>
          </CardActions>
        </Card>
      )}

      {loading && (
        <div>
          <CircularProgress /> generating feedback...
        </div>
      )}
    </Container>
  );
};

export default ActionFeedbackForm;
