import { styled, Theme } from '@mui/system';
import { Typography } from '@mui/material';

export const MessageSuperBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const DateTextLeft = {
  color: 'rgba(15, 15, 33, 0.4) !important',
};

export const DateTextRight = {
  color: '#96BCB4 !important',
};

export const MessageLeftBlock = {
  backgroundColor: 'rgba(197, 197, 254, 0.16)',
  marginLeft: 24,
  borderRadius: '12px 12px 12px 0px',
};

export const MessageRightBlock = {
  backgroundColor: '#D6F5EE',
  marginRight: 24,
  borderRadius: '12px 12px 0px 12px',
};

export const MessageBlock = styled('div')<{ isCurrentUser: boolean; anchorEl: any }>(
  ({ isCurrentUser, anchorEl }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 419,
    minWidth: 254,
    padding: '12px 12px 12px 12px',
    ...(isCurrentUser ? MessageLeftBlock : MessageRightBlock),
    ...(anchorEl ? { backgroundColor: '#F2F4F7 !important' } : {}),
  }),
);

export const MessageBlockChosen = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: '#F2F4F7 !important',
}));

export const BodyText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: '#0F0F21',
  maxWidth: '450px !important',
  wordWrap: 'break-word',
}));

export const MessageBottomInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: 6,
}));

export const UserNameText = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '16px',
  width: 163,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(isCurrentUser ? DateTextLeft : DateTextRight),
}));

export const DateText = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '16px',
  ...(isCurrentUser ? DateTextLeft : DateTextRight),
}));

export const ExtraMargin = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: '10px',
}));

export const ProgressControls = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const CircularProgressWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  marginRight: '16px',
}));

export const ImgWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  marginTop: '12px',
  paddingTop: '18px',
  borderTop: '1px solid rgba(15, 15, 33, 0.1)',
}));

export const StyledImg = styled('img')(({ theme }: { theme: Theme }) => ({
  objectFit: 'cover',
  width: '100%',
  borderRadius: 12,
}));

export const MessageBottomLeftInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1,
}));

export const MessageBottomRightInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: 0,
}));

export const ReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 12,
  padding: 0,
  margin: 0,
  marginLeft: 2,
}));

export const UnReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  color: '#96BCB4',
}));

export const IsReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  color: '#47B29A',
}));

export const FileImageBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  '&:not(:last-child)': {
    marginBottom: '15px',
  },
}));

export const FileImagePreview = styled('img')(({ theme }: { theme: Theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  width: '100%',
  height: 'auto',
  maxWidth: 180,
  maxHeight: 121,
}));

export const FileImageIcon = styled('img')(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  top: 45,
  left: 0,
  right: 0,
  margin: 'auto',
}));

export const FileImageText = styled(Typography)(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  bottom: 30,
  margin: 'auto',
  fontSize: 12,
  fontWeight: 500,
  color: '#FFFFFF',
  maxWidth: 120,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
